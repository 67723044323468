@import './Vars.scss';

.selecthisopado__status {
    background: $primary-color;
    color: white;
    height: 30px;
    border-radius: 10px 10px 0 0 ;
    padding: 5px;
    width: 120px;
    z-index: 20;
}

.selecthisopado__options {
    position: absolute;
    background: white;
    color: $primary-color;
    padding: 0;
    width: 120px;
    border-radius: 0 0 5px 5px;
    z-index: 0;
    border: 1px solid $primary-color;
    .selecthisopado__choice:hover { 
        background: $primary-color;
        color: white;
        padding: 10px;
        cursor: pointer;
    }   
}

.loadHisopado__form{
    width: 55vw;
    height: fit-content;
}

.editHisopado__form{
    width: 50vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    padding: 5vh;
    .HisopadoCart__users {
        display: flex;
        flex-direction: column;

        .HisopadoCart__user {
            border-radius: 0 0.5rem 1rem 1rem;

            .HisopadoCart__userTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.8rem 1rem 0.8rem 1.8rem;
                border-left: 4px solid grey;

                .HisopadoCart__userName {
                    margin: 0;
                    color: #000;
                    font-size: 1rem;
                    line-height: 1.4rem;
                    font-weight: 600;
                }

                svg {
                    color: grey;
                    font-size: 1rem;
                }
            }

            .HisopadoCart__userData {
                flex-direction: column;
                display: flex;

                & > div {
                    position: relative;
                    margin-bottom: 1rem;
                    padding-left: 1.8rem;
                    padding-right: 0.8rem;

                    &.columns {
                        display: flex;
                        justify-content: space-between;

                        & > div {
                            position: relative;
                            width: 48%;

                            label {
                                left: 0;
                            }
                            
                            input[type="date"] {
                                font-size: 0.8rem;
                            }

                            svg {
                                right: 0.3rem;
                            }
                        } 
                    }

                    label {
                        position: absolute;
                        top: 0;
                        left: 1.8rem;
                        color: grey;
                        font-size: 0.9rem;
                    }

                    input,
                    select {
                        display: block;
                        width: 100%;
                        border: none;
                        border-bottom: 0.1rem solid grey;
                        padding: 1.5rem 0.3rem 0.5rem;
                        outline: none;
                        color: black;
                        font-size: 0.9rem;
                        background: none;
                    }

                    svg {
                        position: absolute;
                        top: 40%;
                        right: 0.8rem;
                        color: grey;
                    }
                }

                .HisopadoCart__btnConfirm {
                    background: $primary-color;
                    color: white;
                    border: none;
                    width: 100%;
                    padding: 1rem 0 1.2rem;
                    border-radius: 0px 0px 1rem 1rem;
                    font-weight: 800;
                    font-size: 1.3rem;
                    outline: none;
                }
            }
        }
    }

    .HisopadoCart__btnContainer {
        display: flex;
        flex-direction: column;
        width: 85px;
        margin: 3vw;

        .HisopadoCart__btnSend {
            outline: none;
            border-radius: 50%;
            font-size: 2.5rem;
            text-align: center;
            padding: 15px;
            border: none;
            box-shadow: 0px 9px 20px -3px rgba(126, 147, 163, 0.25);
            font-style: normal;
            line-height: 46px;
            background-color: $primary-color;
            color: white;
        }
    }
}

.HisopadoCart {
    background-color: white;
    position: relative;
    max-height: 72vh;

    .HisopadoCart__container {
        
        width: 90%;
        margin: auto;
        padding-bottom: 200px;

        .HisopadoCart__goBack {
            padding: 0.8rem 1.4rem 0.8rem 0;
            box-shadow: 0px 2px 5px rgba(199, 199, 199, 0.14);
            border-radius: 0 0 0.5rem 0.5rem;

            svg {
                color: grey;
                font-size: 1.5rem;
            }
        }

        .HisopadoCart__header {
            .HisopadoCart__title {
                width: 90%;
                text-align: left;
                color: $primary-color;
                font-size: 1.5rem;
                line-height: 25px;
                font-weight: 900;
                margin-top: 0.5rem;
                margin-bottom: 1rem;
            }

            .HisopadoCart__text {
                color: black;
                font-size: 1rem;

                &:first-of-type {
                    margin-bottom: 1rem;
                }

                span {
                    font-weight: 600;
                }
            }

            .HisopadosCart__empty {
                margin-top: 3rem;
                text-align: center;

                h2 {
                    color: $primary-color;
                    font-size: 1.5rem;
                    line-height: 25px;
                    font-weight: 900;
                }

                p {
                    color: black;
                    font-size: 1rem;
                }
            }
        }

        .HisopadoCart__userSection {
            width: 100%;
            padding: 1rem 0;
            max-height: 62vh;
            overflow: auto;

            .HisopadoCart__users {
                display: flex;
                flex-direction: column;

                .HisopadoCart__user {
                    border-radius: 0 0.5rem 1rem 1rem;

                    .HisopadoCart__userTitle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0.8rem 1rem 0.8rem 1.8rem;
                        border-left: 4px solid grey;

                        .HisopadoCart__userName {
                            margin: 0;
                            color: #000;
                            font-size: 1rem;
                            line-height: 1.4rem;
                            font-weight: 600;
                        }

                        svg {
                            color: grey;
                            font-size: 1rem;
                        }
                    }

                    .HisopadoCart__userData {
                        display: none;
                        flex-direction: column;

                        &.open {
                            display: flex;
                        }

                        & > div {
                            position: relative;
                            margin-bottom: 1rem;
                            padding-left: 1.8rem;
                            padding-right: 0.8rem;

                            &.columns {
                                display: flex;
                                justify-content: space-between;

                                & > div {
                                    position: relative;
                                    width: 48%;

                                    label {
                                        left: 0;
                                    }
                                    
                                    input[type="date"] {
                                        font-size: 0.8rem;
                                    }

                                    svg {
                                        right: 0.3rem;
                                    }
                                } 
                            }

                            label {
                                position: absolute;
                                top: 0;
                                left: 1.8rem;
                                color: grey;
                                font-size: 0.9rem;
                            }

                            .label {
                                color: grey;
                                font-size: 0.9rem;
                                font-weight: 600;
                            }

                            input,
                            select {
                                display: block;
                                width: 100%;
                                border: none;
                                border-bottom: 0.1rem solid grey;
                                padding: 1.5rem 0.3rem 0.5rem;
                                outline: none;
                                color: black;
                                font-size: 0.9rem;
                                background: none;
                            }

                            svg {
                                position: absolute;
                                top: 40%;
                                right: 0.8rem;
                                color: grey;
                            }
                        }

                        .HisopadoCart__btnAddress {
                            background: none;
                            border: none;
                            color: $primary-color;
                            font-weight: 800;
                            font-size: 1.1rem;
                            line-height: 1.4rem;
                            margin: 0.5rem 0 1rem;
                        }

                        .HisopadoCart__btnConfirm {
                            background: $primary-color;
                            color: white;
                            border: none;
                            width: 100%;
                            padding: 1rem 0 1.2rem;
                            border-radius: 0px 0px 1rem 1rem;
                            font-weight: 800;
                            font-size: 1.3rem;
                            outline: none;
                        }

                        .HisopadoCart__btnDelete {
                            border: none;
                            background: none;
                            margin-top: 1rem;
                            padding-bottom: 1rem;
                            color: grey;
                            font-size: 1.1rem;
                            outline: none;
                        }
                    }
                }
            }

            .HisopadoCart__addContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 15px;
                margin-top: 1rem;
                
                .HisopadoCart__btnContainer {
                    display: flex;
                    flex-direction: column;
                    width: 85px;
                    margin: 3vw;

                    .HisopadoCart__btnSend {
                        outline: none;
                        border-radius: 50%;
                        font-size: 2.5rem;
                        text-align: center;
                        padding: 15px;
                        border: none;
                        box-shadow: 0px 9px 20px -3px rgba(126, 147, 163, 0.25);
                        font-style: normal;
                        line-height: 46px;
                        background-color: $primary-color;
                        color: white;
                    }
                    
                    .HisopadoCart__addBTn {
                        outline: none;
                        border-radius: 50%;
                        font-size: 3.5rem;
                        text-align: center;
                        padding: 15px;
                        color: $primary-color;
                        background-color: white;
                        border: none;
                        box-shadow: 0px 9px 20px -3px rgba(126, 147, 163, 0.25);
                        font-style: normal;
                        font-weight: 900;
                        line-height: 46px;
                    }
                    
                    .HisopadoCart__addMsg {
                        color: $primary-color; 
                        font-size: 0.9rem;
                        text-align: center;
                    }
                }
            }
        }

    }
    
    .HisopadoCart__payment {
        position: absolute;
        left: 0;
        bottom: 0;
        box-shadow: 0px -9px 20px -3px rgba(126, 147, 163, 0.25);
        border-radius: 50px 50px 0px 0px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 100% !important;
        background: white;

        .HisopadoCart__payDetails {
            padding: 25px 45px;
            display: flex;
            flex-direction: column;

            .HisopadoCart__payDetail {
                display: flex;
                justify-content: space-between;
                margin: 5px 0;
            }
        }

        .HisopadoCart__payBtn {
            background-color: $primary-color;
            color: white;
            width: 100%;
            border: none;
            outline: none;
            height:55px;
            font-style: normal;
            text-align: center;
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
            padding: 15px 25px;
            border-radius: 25px 25px 0 0;
            cursor: pointer;

            i {
                margin: 3px;
            }
        }
    }
    .hisopados_cross{
        margin-top: 0vh;
    }
}

.find_by_email_result{
    margin-top: -38vh;
}

.switch-hisopados{
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .switch-hisopados-text{

    }
}

.instructions-container{
    display: flex;
    flex-direction: column;
    width: 60vw;
    padding: 10vw;
    .blue-text{
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
    }
    .blue-text:hover{
        cursor: pointer;
    }
}