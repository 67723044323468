@import '../../../styles/Vars.scss';

.statistics__container {
	padding: 10px;
	.statistics__twoSectionsRow {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.statistics__title {
		color: $uma-text;
		width: 100%;
		font-weight: 700;
		font-family: Nunito;
		font-size: 1.4rem;
		margin: 20px 0 10px;
		span {
			text-decoration: underline;
		}
	}
	.statistics__general {
		display: flex;
		justify-content: flex-start;
        flex-wrap: wrap;
		width: 100%;
		.statistics__littlebox {
			min-width: 250px;
			height: 80px;
			box-shadow: 0px 0px 0.778747px rgba(0, 0, 0, 0.78), 0px 0px 3.11499px rgba(0, 0, 0, 0.55);
			border-radius: 6.97627px;
			position: relative;
			overflow: hidden;
			margin: 5px;
			.littlebox__header {
				position: absolute;
				left: 5px;
				top: 5px;
				height: 5px;
				width: 95%;
				border-radius: 6.97627px;
				&.first {
					background: #B6A9D3;
				}
				&.second {
					background: #D3A9A9;
				}
				&.third {
					background: #9DC2FF;
				}
				&.fourth {
					background: #A9BAD3;
				}
				&.fifth {
					background: #A9BAD3;
				}
				&.sixth {
					background: #acd3a9;
				}
			}
			.littlebox__statEvolution {
				display: flex;
				.littlebox__number {
					font-family: 'Red Hat Display', sans-serif;
					font-weight: 900;
					font-size: 1.6rem;
					margin: 20px 0 5px 0;
					line-height: 1.5rem;
				}
			}
			.littlebox__stat {
				font-family: 'Red Hat Display', sans-serif;
				color: $uma-text-secondary;
				font-weight: 700;
			}
		}
	}
	.statistics__doctors {
		width: 100%;
	}

	.statistics__nextAppointments {
		min-width: 500px;
		width: 100%;
		table {
			width: 100%;
		}
		thead {
			border-bottom: 1px solid #DFE0EB;
			padding: 5px;
			font-weight: 600;
			color: $uma-text-secondary;
			td {
				padding: 0 15px;
				font-family: 'Mulish', sans-serif;
				color: #9FA2B4;
			}
		}
		tbody {
			border: none;
			.nextAppointments__empty {
				font-weight: 900;
				padding: 15px;
			}
			.nextAppointments__name {
				font-family: 'Mulish', sans-serif;
				color: $uma-text;
				font-weight: 600;
			}
			.nextAppointments__coverage {
				font-family: 'Mulish', sans-serif;
				color: $uma-text;
				font-weight: 300;
			}
			.nextAppointments__date {
				display: flex;
				flex-direction: column;
				padding: 5px;
				.day {
					color: $uma-text;
					font-weight: 400;
					line-height: 1.2rem;
				}
				.hour {
					color: $uma-text-secondary;
					font-weight: 400;
					line-height: 1rem;
				}
			}
			img {
				width: 30px;
				margin: 10px;
				border-radius: 50%;
			}
		}
	}
	.delayMonitor__container {
		width: 100%;
		margin: 0 10px;
		padding: 0 15px;
		.delayMonitor__range {
			font-size: 0.8rem;
			text-align: center;
			text-decoration: underline;
			cursor: pointer;
		}
		.stats__demand-container {
			width: 100%;
			background: white;
		}
		.recharts-wrapper {
			width: 100% !important;
			svg {
				width: 100%;
			}
		}
		.recharts-legend-wrapper {
			max-width: 500px;
			margin-top: -20px;
			li {
				display: flex;
				width: 200px;
				align-items: center;
			}
			.recharts-legend-item-text {
				font-size: 0.8rem;
				width: 500px;
			}
		}
		small {
			width: 100%;
			margin-top: 10px;
		}
	}
	.performance__container {
		width: 100%;
		.performance__doctorStats {
			display: flex;
			.stat__box {
				cursor: pointer;
				background: $white-color;
				color: $deep-blue;
				line-height: 0.8rem;
				text-align: center;
				border-radius: 5px;
				display: flex;
				justify-content: center;
				margin: 5px;
				p {
					margin-top: 10px;
				}
			}
			.stat-stars {
				padding: 0 15px;
			}
			.stat-subtitle {
				font-weight: bold;
				margin-top: 5px;
				font-size: 14px;
			}
		}
	}
	.patientReviews__container {
		width: 100%;
		margin: 0 10px;
		padding: 0 15px;
		min-height: 150px;
		max-height: 250px;
		overflow: hidden;
		.review_empty {
			font-weight: 500;
			text-align: center;
		}
		.review__container {
			display: flex;
			.review__profilePhoto {
				margin: 5px;
				svg {
					background: $primary-color;
					color: $white-color;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					padding: 7px;
				}
				img {
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}
			}
			.review__data {
				padding: 5px;
				line-height: 1rem;
				font-family: 'Mulish', sans-serif;
				p {
					margin: 0;
				}
				.review__name {
					font-size: 0.9rem;
					font-weight: 700;
				}
				.review__date {
					font-size: 0.8rem;
					font-weight: 500;
					color: $uma-text-secondary;
				}
				.review__stars {
					margin-top: -3px;
				}
				.review__comment {
					font-size: 0.8rem;
					margin-top: 5px;
					font-weight: 500;
				}
			}
		}
	}
}