.asignaciones_modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    ol {
        display: flex;
        justify-content: center;
        list-style-position: inside;
        width: 100%;
        
        li {
            width: 25%;
            text-align: center;
            background: #ddd;
            padding: 0.5rem;
            font-weight: 600;
            color: #333;

            &.active {
                background: #0A6DD7;
                color: #fff;
            }
        }
    }

    .uploading_csv {
        margin: 1rem 0 0;
    }

    label {
        border: 2px dashed #ddd;
        border-radius: 1rem;
        padding: 1.5rem 1rem;
        width: 50%;
        text-align: center;
        margin-top: 2rem;

        p {
            margin: 0.5rem 0;
            color: #0A6DD7;
            font-weight: 500;
        }

        input {
            display: none;
        }

        span {
            cursor: pointer;
            color: #0A6DD7;
            padding: 0.5rem 1rem;
            border-radius: 1.5rem;
            background: none;
            border: 1px solid #0A6DD7;
            font-weight: 600;
            transition: background 0.5s, color 0.5s;

            &:hover {
                background: #0A6DD7;
                color: #fff;
            }
        }
    }

    .selected_file {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        p {
            margin: 0 2rem 0 0;
            color: #333;
            font-weight: 500;
        }

        button {
            cursor: pointer;
            border: none;
            background: none;
            color: #333;
            font-weight: 800;
        }
    }

    & > p {
        color: #333;
        font-size: 0.8rem;
        margin-top: 1rem;
    }

    .contacts_preview {
        width: 80%;
        margin: auto;

        h2 {
            font-size: 1.1rem;
            text-align: center;
            color: #333;
            font-weight: 500;
            line-height: 2rem;

            .correct {
                color: #0A6DD7;
            }

            .error {
                color: #CE3333;
            }

            button {
                border: none;
                background: none;
                color: #0A6DD7;
                text-decoration: underline;
                font-size: 0.9rem;
                margin-left: 1rem;
                font-weight: 500;
            }
        }

        .contacts_list {
            height: 200px;
            overflow-y: auto;
            margin-bottom: 1rem;
            margin-top: 1rem;
            
            .row {
                display: grid;
                grid-template-columns: repeat(13, 1fr);
                align-items: center;
                width: 100%;
                padding: 1rem 0.2rem 0.5rem;
                border-bottom: 1px solid #DFE0EB;

                &.error {
                    background: #CE3333;

                    .col {
                        color: #fff;
                    }
                }

                &:first-child {
                    padding: 0 0 0.8rem;

                    .col {
                        color: #9FA2B4;
                        font-weight: 500;
                        font-size: 0.7rem;
                    }
                }

                .col {
                    text-align: center;
                    overflow: hidden;
                    color: #5C5C5C;
                    font-weight: 500;
                    font-size: 0.7rem;
                }
            }

        }
    }
    
    .codeSection{
        h2 {
            font-size: 1.5rem;
            text-align: center;
            color: #333;
            font-weight: 500;
            line-height: 2rem;
        }
        .selector{
            text-align: center;
            padding: 10%;
            width: 100%;
        }
    }

    .finish_upload {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 200px;
            margin: 1.5rem auto;
        }

        h2 {
            color: #0A6DD7;
            font-size: 1.3rem;
            font-weight: 800;
        }

        p {
            color: #1E1E1E;
            margin: 0;
            line-height: 1.5rem;
            font-size: 0.9rem;

            &:last-of-type {
                margin-top: 1rem;
            }
        }
    }

    .buttonsContainer {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        button {
            cursor: pointer;
            background: #0A6DD7;
            color: #fff;
            padding: 0.5rem 2rem;
            border-radius: 1.5rem;
            border: none;
            font-weight: 600;
            transition: background 0.5s;

            &:disabled {
               background: rgba(10, 109, 215, 0.5); 
            }

            &:not(:disabled):hover {
                background: #0556ad;
            }
        }
    }
}