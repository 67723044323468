.header__arrow--back {
    position: relative;
    z-index: 3;
}

.deliveryService__filtrosDeQuery{
    position: absolute;
    width: 95vw;
    display: grid;
    grid-template-columns: 1;
    justify-items: end;
    z-index: 5;
    span{
        margin: 0 10px;
    }

    & > div:last-child {
        background: rgba(0, 0, 0, 0.7);
        margin-top: 20px;
        padding: 8px;

        span {
            font-weight: bold;
            display: block;
            margin-bottom: 8px;
            text-align: right;
            color: #fff;
        }

        ul {
            list-style: none;

            li {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                color: #fff;
                margin-bottom: 5px;

                .color {
                    display: block;
                    margin-left: 10px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #fff;

                    &.hisopado {
                        background: #4d9acc;
                    }

                    &.hisopadoCurso {
                        background: #ef9b43;
                    }

                    &.vacuna {
                        background: #714ccb;
                    }

                    &.vacunaCurso {
                        background: #c84ea4;
                    }

                    &.auto {
                        background: #3a65bd;
                    }

                    &.autoCurso {
                        background: #f56c42;
                    }
                }
            }
        }
    }
}

.deliveryService__map {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .deliveryService__info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .deliveryService__address--col {
            width: 100%;
            padding: 10px;
            p {
                font-size: 0.8rem;
            }
        }

        .deliveryService__info--col {
            width: 50%;
        }

        .deliveryService__info--text {
            margin-bottom: 0;
            font-size: 1.2rem;
        }

        .deliveryService__info--btn {
            font-size: 1.2rem;
            padding: 5px 10px;
            margin-right: 10px;
            margin-top: 5px;
            border-radius: 5px;
            color: white;
            background: blue;
            border: none;
            font-weight: bold;
            width: 100%;
        }

        .deliveryService__btn {
            font-size: 1.2rem;
            padding: 5px 10px;
            margin-right: 10px;
            margin-top: 5px;
            border-radius: 5px;
            color: white;
            background: blue;
            border: none;
            font-weight: bold;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;

            & > svg {
                display: block;
                width: 30px;
                font-size: 1.5rem;
                margin-left: 5px;
            }

            &.deliveryService__btn--cancel {
                background: red;
            }
        }
    }

    .deliveryServiceRouteLab__info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .deliveryServiceRouteLab__info--col {
            width: 50%;

            .deliveryServiceRouteLab__info--title {
                text-transform: capitalize;
                font-weight: bold;
                margin-bottom: 0.5rem !important;
            }

            .deliveryServiceRouteLab__info--title {
                margin-bottom: 0;
                font-size: 1.2rem;
            }

            .deliveryService__btn {
                font-size: 1.2rem;
                padding: 5px 10px;
                margin-right: 10px;
                margin-top: 5px;
                border-radius: 5px;
                color: white;
                background: blue;
                border: none;
                font-weight: bold;
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                outline: none;
    
                & > svg {
                    font-size: 1.5rem;
                }
            }
        }
    }
}