@import '../../../styles/Vars.scss';
@import '../../../styles/Fonts.scss';

section{
  position: relative;
.topTableInfoContainer{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    top: -47px;
    right: 0;
    margin-top: 10px;
      .filtersContainer{
        display: flex;
        align-items: center; 
        flex-direction: column;   
        .filters{
          display: flex;
          flex-direction: column;
          margin: 5px;
          width: 100%;
          .filter{
            margin: 5px;
            padding: 5px;
            align-items: center;
            display: flex;
            border-bottom: 1px solid #0a6dd7;
            select {
              margin: 0 5px;          
            }
            input{
              margin: 0 5px;
            }
            label {
              margin: 0 5px;
            }
            
          }
        }
        .buttons{
          button{
            margin: 0 10px;
            cursor: pointer;
            border: 1px solid rgb(212, 211, 211);
            background: $uma-primary;
            padding: 0.3rem 1rem;
            font-size: 15px;
            color: $white-color;
            font-weight: 700;
          }
        }
      }
      .exportToExcelContainer {
            display: flex;
            align-items: center;
            margin-left: 30px;
            position: relative;
            .buttonExcel{
              border: 1px solid rgb(212, 211, 211);
              padding: 1px;
              font-size: 20px;
              color: #2d572c;
              width: 32px;
              border-radius: 6px;
              background-color:  #ffffff;
              cursor: pointer;
            }
            .dropdown-content{
              display: flex;
              opacity: 0;
              flex-direction: column;
              position: absolute;
              z-index: 10;
              width: 0;
              overflow: hidden;
              height: auto;
              pointer-events:none;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              -ms-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              span{
                background-color: #ffffff;
                padding: 10px;
                font-size: 0px;
                cursor: pointer;
                text-align: center;                
                display: flex;
                flex-direction: column;
                &:hover {
                  background-color: #e4e4e4;
                }
                p{
                  visibility: hidden;
                  opacity: 0;
                  text-decoration: none;                            
                }
                a{
                  visibility: hidden;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                  text-decoration: none;
                  color: rgb(22, 22, 22);
                }
              }

              &.dropToUp{
                bottom: 33px;
                right: 10px;
              }
              &.dropToDown{
                top: 33px;
                right: 0px;
              }

              &.active{
                display: flex;
                pointer-events:auto; 
                height: auto;
                width: 10vw;
                opacity: 1;
                box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.75);                
                span{
				  color:rgb(22, 22, 22);
                  font-size: 15px;
				  margin: 0;
				  padding: 5px 5px;
                  a{
                    visibility: visible;
                    opacity: 1;                    
                  }
                  p{
                    visibility: visible;
                    opacity: 1;
                    font-size: 10px;
                    color: rgb(224, 64, 64);
                    margin: 0;
                  }
                }
              }
            }            
            button{
            cursor: pointer;

              border: 1px solid rgb(212, 211, 211);
              background: $uma-primary;
              padding: 0.3rem 1rem;
              font-size: 15px;
              color: $white-color;
              font-weight: 700;
              &.disabled{
                pointer-events: none;          
              }
              &:active{
                .dropdown-content{
                  display: flex;
                  width: 12px;
				  span {
					  color:rgb(22, 22, 22);
				  }
                }
              }
            }
          } 
}

.table {
    margin-top: 1rem;
    max-width: 100%;
    overflow-x: auto;
    .noServices {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 200px;
    }
    .row {
        display: grid;
        grid-template-columns: 150px repeat(5, 120px) 150px 150px 200px repeat(17, 120px);
        align-items: center;

        
        // &.no_edit {
        //     grid-template-columns: repeat(15, 1fr);
        // }
        
        // &.ceco {
        //     grid-template-columns: repeat(17, 1fr);
        // }
        
        // &.ceco.no_edit {
        //   grid-template-columns: repeat(16, 1fr);
        // }
        // &.domicilio{
        //   grid-template-columns: repeat(15, 1fr);
        // }
        
        &:nth-child(odd) {
          .col {
            background: $uma-input;
          }
        }
        // &.cancel{
        //   grid-template-columns: repeat(16, 1fr); //sudo important!
        // }
        // &.cancelRow{
        //   background-color: #ff7878;
        // }

        &.th {
            .col {
                .title {
                    background: $uma-primary;
                    color: $white-color;
                    font-weight: 600;
                    text-align: center;
                    font-size: 0.8rem;
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &.tc {
            .col {
                min-height: 80px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                &.address {
                  position: relative;
                  span {
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    font-size: 0.5rem;
                    background: $correct;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: $white-color;
                    line-height: 1;
                  }
                }

                &.dowloadConstancy{
                    .pdfWrapper{
                        display: none;
                    }
                }
                &.result {
                    p {
                        width: 100%;
                        padding: 0.5rem 0;
                        font-weight: 600;

                        &.no_detectable {
                            background: $correct;
                            color: $uma-white;
                        }
                        
                        &.detectable {
                            background: $uma-error;
                            color: $uma-white;
                        }

                        &.not_processed {
                            background: $deep-orange;
                            color: $uma-text;
                        }
                    }
                }

                p {
                    color: $uma-text;
                    font-size: 0.8rem;

                    &.ceco {
                      font-size: 0.6rem;
                    }
                }

                button {
                    padding: 0.2rem 1rem;
                    line-height: 1;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: none;
                    border: none;
                    color: $uma-primary;
                    font-size: 1.1rem;
                    cursor: pointer;
                }
            }
        }
    }
}
}