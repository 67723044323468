@import './Vars.scss';

.header {
    background: $uma-primary;
    padding: 0.4rem 0;

    .container {
        padding: 0 2rem;
        height: 1.8rem;

        .menu {
            color: $uma-white;
            font-size: 1.8rem;
            line-height: 1;
            height: 1.8rem;
            display: inline-block;
            cursor: pointer;
        }
    }

    .menu_container {
        width: 100%;
        height: 100vh;
        position: fixed;
        left: -100%;
        top: 0;
        z-index: 5;
        transition: left 0.6s;

        &.open {
            left: 0;
        }

        .menu_content {
            width: 20%;
            background: $uma-white;
            height: 100vh;
            box-shadow: 0.1rem 0 0.7rem rgba(0, 0, 0, 0.5);

            nav {
                height: 100%;
                
                ul {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    list-style: none;

                    li {
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }

                        a {
                            text-decoration: none;
                            font-weight: 600;
                            color: $uma-primary;
                        }
                    }
                }
            }
        }
    }
}