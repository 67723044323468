@import '../../../styles/Vars.scss';

@mixin loader($width: 32px, $height: 32px) {
  display: inline-block;
  width: 36px;
  height: 36px;
  &:after {
    content: ' ';
    display: block;
    width: $width;
    height: $height;
    border-radius: 50%;
    border: 4px solid transparent;
    border-color: transparent #0074bb transparent #36a853;
    animation: loader 1.2s linear infinite;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opaqueText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.umaLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white-color;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .umaLoader__container {
    &:nth-child(2) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .umaLoader__container--img {
      width: 200px;
      height: 200px;
      margin-bottom: 28px;
    }
    .umaLoader__container--loader {
      @include loader($width: 32px, $height: 32px);
      &.absolute {
        position: absolute;
        top: -200px;
        left: 60px;
      }
    }
    .umaLoader__container--text {
      animation: opaqueText 1.2s linear infinite alternate-reverse;
    }
  }
}

.loader {
  @include loader($width: 36px, $height: 36px);
  width: 100%;
  display: flex;
  padding: 10%;
  justify-content: center;
}

.newLoader{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  .text{
      animation-name: parpadeoBubble;
      animation-duration: 2.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      -webkit-animation-name:parpadeoBubble;
      -webkit-animation-duration: 2.5s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;

      @-moz-keyframes parpadeoBubble{  
      0% { opacity: 1.0; }
      50% { opacity: 0.2 }
      100% { opacity: 1.0; }
      }

      @-webkit-keyframes parpadeoBubble {  
      0% { opacity: 1.0; }
      50% { opacity: 0.2 }
      100% { opacity: 1.0; }
      }

      @keyframes parpadeoBubble {  
      0% { opacity: 1.0; }
      50% { opacity: 0.2 }
      100% { opacity: 1.0; }
      }
  }         
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #0A6DD7;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin loader($width: 32px, $height: 32px) {
  display: inline-block;
  width: 36px;
  height: 36px;
  &:after {
    content: ' ';
    display: block;
    width: $width;
    height: $height;
    border-radius: 50%;
    border: 4px solid transparent;
    border-color: transparent #0074bb transparent #36a853;
    animation: loader 1.2s linear infinite;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opaqueText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.umaLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white-color;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .umaLoader__container {
    &:nth-child(2) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .umaLoader__container--img {
      width: 200px;
      height: 200px;
      margin-bottom: 28px;
    }
    .umaLoader__container--loader {
      @include loader($width: 32px, $height: 32px);
      &.absolute {
        position: absolute;
        top: -200px;
        left: 60px;
      }
    }
    .umaLoader__container--text {
      animation: opaqueText 1.2s linear infinite alternate-reverse;
    }
  }
}


.loader {
  @include loader($width: 36px, $height: 36px);
}
