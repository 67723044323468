@import './Vars.scss';

.calendar-container {
    color: $uma-white;
    box-shadow: 0px 9px 20px rgba(126, 147, 163, 0.25);
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    img{
        object-fit: contain;
        width: 80px;
        margin-left: 30px;
    }
    .imgOnSideBarHide{
        margin-left: 10px;
    }
    .tabs-container{
        .calendar-tab, a{
            cursor: pointer;
            color: #5C5C5C;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            padding: 20px 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            svg{
                margin: 0 15px;
                font-size: 24px;
            }
            &.active{
                background-color: #0A6DD7;
                a{
                    color: white;
                }
            }
        }
    }

    .config-tab{
        color: #5C5C5C;
        cursor: pointer;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        padding: 20px 15px;
        box-sizing: border-box;
        width: calc(100%);
        i{
            margin: 0 8px;
            font-size: 24px;
        }
    }
    .iconInHideBar{
        text-align: center;
    }
    .arrowHideSidebar{
        color: #0A6DD7;
        cursor: pointer;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 24px;
        padding: 20px 15px;
        box-sizing: border-box;
        width: calc(100% - 20px);
        margin: 55px auto 0 auto;
        text-align: center;
        transition: 0.3s all ease-in-out;
        text-align: center;
        i{
            margin: 0 8px;
        }
    }
    .turnRound{
        transform: rotate(180deg);
    }
    
}