.div {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
        margin-top: 35px;
        line-height: 29.35px;
        color: #0A6DD7;
        font-family: Nunito;
        font-weight: 900;
        font-size: 28px;
        font-style: normal;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
    }
    .text {
        width: 318px;
        margin-top: 20px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
    }
    .inicio {
        margin-top: 10px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24.55px;
        text-align: center;
        text-decoration-line: underline;
    }
}
