@import './Vars.scss';
@import './Fonts.scss';

.hisopados {
    padding: 2rem 0;

    .container {
        width: 100%;
        padding: 0 30px 0;
        margin: auto;

        .hisopados_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                color: $uma-primary;
                font-size: 2rem;
                font-weight: 600;
            }

            div {
                width: 70%;
                display: flex;
                
                .search_input {
                    flex: 2;
                    color: $uma-text;
                    font-size: 0.8rem;
                }

                button {
                    margin-left: 1rem;
                    flex: 1;
                    background: $uma-primary;
                    border: none;
                    border-radius: 0.8rem;
                    color: $uma-white;
                    cursor: pointer;
                    font-weight: 600;
                    padding: 0 1rem;
                }

                select {
                    flex: 1;
                    margin-left: 1rem;
                }
            }

        }
        .anotherData{
            display: flex;
            .salesOfTheDay {
                margin-top: 1rem;
            
                p {
                    color: $uma-primary;
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            
                ul {
                    display: flex;
                    list-style: none;
                    margin-top: 0.5rem;
            
                    li {
                        color: $uma-primary;
                        padding: 0.3rem 1rem;
                        margin-right: 1rem;
                        background-color: $white-color;
                        font-weight: 700;
                        border-bottom: 1px solid $uma-primary;
                    }
                    button {
                        background: $uma-primary;
                        padding: 0.3rem 1rem;
                        margin-right: 1rem;
                        color: $white-color;
                        font-size: 18px;
                        font-weight: 700;
                        outline: none;
                        border: none;
                        cursor: pointer;
                    }
                }
            }
            .filtrosDeQuery{
                display: flex;
                span {
                    align-self: flex-end;
                    margin-bottom: 3px;
                    margin-right: 4px;
                }
                select {
                    align-self: flex-end;
                    height: 30px;
                }
                input {
                    align-self: flex-end;
                    height: 30px;
                }
            }
        }
    }
}

.hisopados_modalContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: end;

    div {
        display: flex;
        flex-direction: column;

        label {
            margin: 0;
            color: $uma-text;
            font-size: 0.8rem;
        }

        input,
        select {
            height: 2rem;
            font-size: 0.8rem;

            &:disabled {
                background: $uma-input;
            }
        }
    }

    button {
        background: $uma-primary;
        border: none;
        height: 2rem;
        border-radius: 0.8rem;
        color: $uma-white;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            background: $uma-secondary;
        }
    }
}