.constancyContainer{
    height: 100vh;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15vh 10vw;
    .logosContainer{
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        top: 50px;
        width: 100%;
        height: 15vw;
        .logo-uma{
            max-height: 100%;
            width: 20vw;
            object-fit: contain;
        }
    }
    .dataContainer{
        padding: 2vh 0 2vh 0;
        border-bottom: 1px gray solid;
        .patient-data{
            align-self: flex-start;
        }
        .constancy-div{
            display: flex;
            justify-content: center;
            text-align: center;
            width: 80vw;
            .constancy-subtitle{
                margin-right: 2vw;
                justify-self: flex-start;
            }.verify-attention {
        display: flex;
    }
            justify-content: space-evenly;
            text-align: center;
            span{
                width: 20vw;
            }
        }    
    }
    .constancy-qr {
        margin-top: 5vh;
    }

    .verify-attention {
        margin-top: 20px;
        display: flex;
        align-items: center;

        section {
            margin-right: 20px;

            div {
                margin-bottom: 10px;
                
                p {
                    margin-bottom: 5px;
                }
            }
        }
    }
}