
// NEW VARS UMA V2

$uma-primary: #0a6dd7; 
$uma-secondary: #A13DDF;
$uma-pink: #E9008C;
$uma-text: #1E1E1E;
$uma-text-secondary: #5C5C5C;
$white-color: #FFFFFF;
$correct: #009042;
$correct-disabled: #80C7A1;
$uma-input: #B6B6B644;
$uma-disabled: #C0C3CC;
$uma-error: #CF2626;
$dark-color: #0d0d0d;
$uma-background: #C4C4C4;
$uma-accent: #E9008C;
$uma-dark: #343434;
$uma-white: #fff;

// Viejas vars
$primary-blue: #45a7f5;
$light-gray: #eaeaea;
$middle-gray: #afafaf;
$dark-gray: #797979;
$bg-individualchat: #ededed;
$color-font: #989898;
$doc-msg: #c5daeb;
//
$primary-color: #42a5f5;
$header-color: #0071f2;
$primary-background: #42a5f5;
$secondary-background: #1565c0;
$whater-blue: #4fa1d1;
$blue-button: #42a5f6;
$header-background: #373e46;
$footer-background: #373e46;
$modal-back: #5a5a5a;
$white-color: #fff;
$primary-cyan: #3fd6d2;
// Gray
$extreme-light-gray: #f3f3f3;
$light-gray: #e3e7f2;
$middle-gray: #9a9fa2;
$border-gray-button: rgb(212, 211, 211);
$dark-gray: #373e46;
$circle-gray: #6c6c6d;
$hover-gray: #e6e6e6;
$border-darker: #e0e0e0;
$text-dark: #6f6f6f;
$text-light: #8a8a8a;
$data-grey: #b3b3b3;
$special-grey: #6c757d;
$intense-gray: #5C5C5C;
// Blue
$deep-blue: #3399cc;
$deep-blue-b: #1976d2;
$light-blue: #bacada;
$gray-font: #696969;
$green-high: #55dad7;
$green-highlight: #3caea4;
$head-blue: #2d758d;
$head-lightblue: #cfe2f2;
$radio-content: #4479af;
$body-blue: #81adba;
$body-light-blue: #e3e7f2;
$circle-blue: #508a9e;
$main-blue: #2196f3;
$saturated-violet: #c72db5;
$blue-hover-btn: #3981cf;
// Green
$deep-green: #66cc66; // old -> #669933
$light-green: #a0c57b;
$saturated-green: #80da26;
$green-uma: #287a3c;
// Red
$deep-red: #cc6666; // old -> #cf4917
$light-red: #ff867d;
$pink: #dc69a9;
$red-high: #ef5350;
// Orange
$deep-orange: #ff9933;
$light-orange: #ffb56b;
// Brown
$deep-brown: #cc9966;
$light-brown: #eae6de;
$head-brown: #d1b284;
$dark-brown: #77582b;
$body-brown: #e2d1b7;
$body-dark-brown: #d1b284;
// Purple
$purple: #663399;
//
$yellow: #ffcc33;

$regular-borders: 6px;

/* Helpers */
.dBlock {
	display: block !important;
}

.dFlex {
	display: flex !important;
}
