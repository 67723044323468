@import './Vars.scss';

.paginationNav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .pagination {
        position: relative;
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .pageItem {
            list-style: none;
            
            button {
                border: none;
                background: none;
                color: $uma-text;
                font-weight: 700;
                margin: 0 0.3rem;
                padding: 0;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.3rem;

                &:hover {
                    background: $uma-input;
                }
            }
            
            &.active {
                button {
                    background: $uma-primary;
                    color: $white-color;
                }
            }
        }

        .arrowPrev {
            position: absolute;
            left: -1.8rem;

            button {
                svg {
                    font-size: 0.8rem;
                }
            }
        }

        .arrowNext {
            position: absolute;
            right: -1.8rem;

            button {
                svg {
                    font-size: 0.8rem;
                }
            }
        }
    }
}