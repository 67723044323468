@import '../../../../styles/Fonts.scss';
@import '../../../../styles/Vars.scss';

.enfermeros_table{
.topTableInfoContainer{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    
        .exportToExcelContainer {
            display: flex;
            align-items: center;
            margin-left: 30px;
            position: relative;
            .buttonExcel{
              border: 1px solid rgb(212, 211, 211);
              padding: 1px;
              font-size: 20px;
              color: #2d572c;
              width: 32px;
              border-radius: 6px;
              background-color:  #ffffff;
              cursor: pointer;
            }
            .dropdown-content{
              display: flex;
              opacity: 0;
              flex-direction: column;
              position: absolute;
              z-index: 10;
              width: 0;
              overflow: hidden;
              height: auto;
              pointer-events:none;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              -ms-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              span{
                background-color: #ffffff;
                padding: 10px;
                font-size: 0px;
                cursor: pointer;
                text-align: center;                
                display: flex;
                flex-direction: column;
                &:hover {
                  background-color: #e4e4e4;
                }
                p{
                  visibility: hidden;
                  opacity: 0;
                  text-decoration: none;                            
                }
                a{
                  visibility: hidden;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                  text-decoration: none;
                  color: rgb(22, 22, 22);
                }
              }

              &.dropToUp{
                bottom: 33px;
                right: 10px;
              }
              &.dropToDown{
                top: 33px;
                right: 0px;
              }

              &.active{
                display: flex;
                pointer-events:auto; 
                height: auto;
                width: 10vw;
                opacity: 1;
                box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.75);                
                span{
				  color:rgb(22, 22, 22);
                  font-size: 15px;
				  margin: 0;
				  padding: 5px 5px;
                  a{
                    visibility: visible;
                    opacity: 1;                    
                  }
                  p{
                    visibility: visible;
                    opacity: 1;
                    font-size: 10px;
                    color: rgb(224, 64, 64);
                    margin: 0;
                  }
                }
              }
            }            
            button{
            cursor: pointer;

              border: 1px solid rgb(212, 211, 211);
              background: $uma-primary;
              padding: 0.3rem 1rem;
              font-size: 15px;
              color: $white-color;
              font-weight: 700;
              &.disabled{
                pointer-events: none;          
              }
              &:active{
                .dropdown-content{
                  display: flex;
                  width: 12px;
				  span {
					  color:rgb(22, 22, 22);
				  }
                }
              }
            }
          } 
}

.table {
    margin-top: 1rem;
    .noServices {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 200px;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 10px;
        padding: 0.5rem;
        align-items: center;
                
        &:nth-child(odd) {
            background: $uma-input;
        }

        &.th {
            background: $uma-primary;

            .col {
                .title {
                    color: $uma-white;
                    font-weight: 600;
                    text-align: center;
                    font-size: 0.8rem;
                }
            }
        }

        &.tc {
            &:hover {
                background: $uma-disabled;
            }

            .col {
              background-color: transparent;
                text-align: center;
                &.dowloadConstancy{
                    .pdfWrapper{
                        display: none;
                    }
                }
                &.result {
                    p {
                        &.no_detectable {
                            background: $correct;
                            color: $uma-white;
                            font-weight: 600;
                            padding: 0.2rem 0;
                        }
                        
                        &.detectable {
                            background: $uma-error;
                            color: $uma-white;
                            font-weight: 600;
                            padding: 0.2rem 0;
                        }
                    }
                }

                p {
                    color: $uma-text;
                    font-size: 0.8rem;

                    &.ceco {
                      font-size: 0.6rem;
                    }
                }

                button {
                    padding: 0.2rem 1rem;
                    line-height: 1;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: none;
                    border: none;
                    color: $uma-primary;
                    font-size: 1.1rem;
                    cursor: pointer;
                }
            }
        }
        &.stock {
          grid-template-columns: repeat(8, 1fr);
        }
    }
}
}