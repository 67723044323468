@import './Vars.scss';
@import './Fonts.scss';

* {
outline: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    
    button:focus,
    button:active {
        filter: brightness(85%);
    }
    
    a {
        text-decoration: none;
    }

    // Títulos genéricos
    h2 {
        font-family: Nunito, 'sans serif';
        color: $uma-primary;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    h3 {
        font-family: Nunito, 'sans serif';
        font-size: 1.3rem;
        line-height: 2rem;
        color: $uma-dark;
    }

    h5 {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-top: 20px;
    }

    // Inputs genéricos
    input,
    select {
        border: 1px solid #b6b6b6;
        border-radius: 0.8rem;
        padding: 0.3rem 0.5rem;
        color: $uma-dark;
        background: $white-color;
        font-size: 0.8rem;
        
        &::placeholder {
            color: #b6b6b6;
        }
    }
    // Labels genéricos
    label {
        margin: 0;
        padding: 0;
        color: $uma-dark;
        font-weight: 700;
        font-size: 0.8rem;
        line-height: 1.5rem;
        margin-bottom: 0.4rem;
    }

    // Botones genericos
    .umaBtn {
        border-radius: 15px;
        padding: 5px;
        margin: 5px;
        width: 150px;
        color: $white-color;
        font-weight: 700;
        border: none;
        background: $uma-primary;
        padding: 0.5rem 0.5rem;
        cursor: pointer;
    
        &.secondary {
            background: $uma-secondary;
        }
        
        &.secondary-outline {
            background: none;
            border: 1px solid $uma-secondary;
            color: $uma-secondary;
        }
        
        &.primary-outline {
            background: none;
            border: 1px solid $uma-primary;
            color: $uma-primary;
        }

        &:disabled {
        cursor: not-allowed;
        }
    }

    .errormsg {
        margin-top: 2rem;
        color: $uma-error;
        font-weight: bold;
        list-style: none;
    }
}

.disabled::after {
    display: none;
}