$width: 40px;
$height: 40px;

.mapMarker {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: $width;
	height: $height;
	position: absolute;
	left: -$width / 2;
	top: -$height / 2;
	.mapMarker__address_visible{
        margin: 0vh;
        z-index: 100;
    }
	&:hover .mapMarker__address_visible{
		visibility: hidden;
	}
    .mapMarker__address{
		visibility: hidden;
        margin: 2vh;
        z-index: 100;
    }
	&:hover .mapMarker__address{
			visibility: visible;
	}
	.mapMarker__container {
		width: inherit;
		height: inherit;
		font-size: 13px;
		.mapMarker__container--text {
			background-color: rgba($color: #000000, $alpha: 0.65);
			padding: 5px 0;
			margin: 0;
			color: #fff;
			border-radius: 6px;
			text-align: center;
			min-width: 100px;
			height: auto;
			max-width: 200px;
			max-height: 200px;
			position: relative;
			bottom: 15px;
		}
		.mapMarker__container--icon {
			color: #0465bd;
			padding: 10px;
			font-size: 30px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
        .kike{
            max-width: 2.5vw;
        }
	}
}
