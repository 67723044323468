@import '../../../styles/Vars.scss';
@import '../../../styles/Fonts.scss';

.overlay_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        width: 60%;
        max-height: 92vh;
        background: $uma-white;
        position: relative;
        padding: 1rem;
        border-radius: 0.8rem;

        .modal_header {
            button {
                z-index: 10;
                position: absolute;
                right: 0.8rem;
                line-height: 1;
                border: none;
                background: none;
                font-size: 1.2rem;
                cursor: pointer;
            }
        }
    }
}