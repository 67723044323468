@import './Vars.scss';

.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $uma-primary;

    form {
        background: $uma-white;
        max-width: 50%;
        padding: 1.5rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 1rem;
            min-width: 20rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid $uma-primary;
            background: none;
        }

        button {
            border: 1px solid $uma-primary;
            background: $uma-primary;
            color: $uma-white;
            padding: 0.5rem;
            border-radius: 0.5rem;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}